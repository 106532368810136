import React, {Component} from 'react'
import LoadingIndicator from '../../../components/LoadingIndicator'
import $ from 'jquery';

import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    Legend,
    Tooltip,
    registerables as registerablesJS
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    Legend,
    Tooltip
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Lifetime Hand Statistics',
        },
    },
};


/**
 * https://www.npmjs.com/package/react-chartjs-2
 * https://react-chartjs-2.js.org/
 */
class StatisticsGraphComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_it_us: false,
            is_loaded: false,
            lifetime_percentage: 0,
            statistics: ''
        };

        this.get_user_statistics = this.get_user_statistics.bind(this)

        this.get_user_statistics();
    }

    get_user_statistics() {
        $.ajax({
            url: process.env.API_URL + "statistics",
            xhrFields: {
                withCredentials: true
            },
            type: "get",
            success: d => {
                if (d.code === 'ok') {
                    this.setState({
                        lifetime_percentage: d.result.lifetime_percentage,
                        statistics: d.result.statistics,
                        is_loaded: true
                    })
                }
            }
        });
    }

    render() {
        let li = '';
        if (!this.state.is_loaded) {
            li = <LoadingIndicator />
        }

        return <div>
            { !this.state.is_loaded && li }
            { this.state.is_loaded && <h3>Accuracy percentage all time: {this.state.lifetime_percentage}%</h3> }
            <div style={{ maxWidth:'800px'}}>
                { this.state.is_loaded && <Chart type='bar' options={options} data={this.state.statistics} /> }
            </div>
        </div>
    }
}

export default StatisticsGraphComponent
