import React, {Component} from 'react'
import $ from 'jquery';
import CometStorage from "../../../../../web-lib/PokerPatternStorage";
import {USER_OBJECT_KEY} from "../../../../../web-lib/Constants";
import {Redirect} from "react-router-dom";
import {HOME_URL} from "../../WebsiteConstants";


class Signup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error_message: '',
            username: '',
            password: '',
            repeat_password: '',
            email: ''
        };

        this.signup = this.signup.bind(this);
        this.onchange_username = this.onchange_username.bind(this);
        this.onchange_email = this.onchange_email.bind(this);
        this.onchange_password = this.onchange_password.bind(this);
        this.onchange_repeat_password = this.onchange_repeat_password.bind(this);
    }

    onchange_username(e) {
        this.setState({
            username: e.target.value.trim()
        });
    }

    onchange_email(e) {
        this.setState({
            email: e.target.value.trim()
        });
    }

    onchange_password(e) {
        this.setState({
            password: e.target.value.trim()
        });
    }

    onchange_repeat_password(e) {
        this.setState({
            repeat_password: e.target.value.trim()
        });
    }

    signup(e) {
        e.preventDefault();

        let username_regex = new RegExp(/^[a-z0-9_]*$/i);

        if (this.props.user_object.signedin === false) {
            if (this.state.username === '' || this.state.password === '' || this.state.repeat_password === '') {
                this.setState({ error_message: 'Entries can not be blank' });
            } else if (this.state.password !== this.state.repeat_password) {
                this.setState({ error_message: 'Passwords did not match' });
            } else if (!username_regex.test(this.state.username)) {
                this.setState({ error_message: 'Username contains invalid characters.' })
            } else {
                this.setState({ error_message: '' });

                $.ajax({
                    url: process.env.API_URL + "signup",
                    xhrFields: {
                        withCredentials: true
                    },
                    type: "post",
                    data: {
                        username: this.state.username,
                        password: this.state.password,
                        email: this.state.email
                    },
                    success: d => {
                        if (d.code === 'ok') {
                            this.setState({
                                error_message: '',
                                username: '',
                                email: '',
                                password: '',
                                repeat_password: ''
                            });

                            CometStorage.setObject(USER_OBJECT_KEY, {
                                signedin: true,
                                username: d.result.username
                            });
                        } else {
                            this.setState({
                                error_message: d.result,
                                password: '',
                                repeat_password: ''
                            });

                            CometStorage.setObject(USER_OBJECT_KEY, {
                                signedin: false,
                                username: ''
                            });
                        }

                        this.props.localstorage();
                    }
                });
            }
        }
    }

    render() {
        if (this.props.user_object.signedin) {
            return <Redirect to={HOME_URL} />;
        }

        return <div>
            <div className="error_msg">
                <span id="error_message">{this.state.error_message}</span>
            </div>
            <form id="signup_form" onSubmit={this.signup}>
                <span>Username (a-z, 0-9, and underscore only)*</span><br />
                <input id="username" value={this.state.username} onChange={this.onchange_username} /><br />
                <span>Email Address</span><br />
                <input id="email" value={this.state.email} onChange={this.onchange_email} /><br />
                <span>Password*</span><br />
                <input id="password" type="password" value={this.state.password} onChange={this.onchange_password} /><br />
                <span>Repeat Password*</span><br />
                <input id="repeatpassword" type="password" value={this.state.repeat_password} onChange={this.onchange_repeat_password} /><br />
                <input type="submit" value="Signup" />
            </form>
        </div>
    }
}

export default Signup
