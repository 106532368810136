import React, {Component} from 'react'
import $ from "jquery";
import CometStorage from "../../../../../web-lib/PokerPatternStorage";
import {USER_OBJECT_KEY} from "../../../../../web-lib/Constants";
import {Redirect} from "react-router-dom";
import {HOME_URL} from "../../WebsiteConstants";
import {MESSAGES} from "./configuration";


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            error_msg: '',
            just_signed_in: false
        };

        this.signin = this.signin.bind(this);
        this.onchange_username = this.onchange_username.bind(this);
        this.onchange_password = this.onchange_password.bind(this);
    }

    onchange_username(e) {
        this.setState({
            username: e.target.value
        });
    }

    onchange_password(e) {
        this.setState({
            password: e.target.value
        });
    }

    signin(e) {
        if (this.props.user_object.signedin === false) {
            $.ajax({
                url: process.env.API_URL + "signin",
                xhrFields: {
                    withCredentials: true
                },
                type: "post",
                data: {
                    username: this.state.username,
                    password: this.state.password
                },
                success: d => {
                    if (d.code === 'ok') {
                        this.setState({
                            error_message: '',
                            username: '',
                            password: '',
                            just_signed_in: true
                        });

                        CometStorage.setObject(USER_OBJECT_KEY, {
                            signedin: true,
                            username: d.result.username
                        });

                        this.props.localstorage();
                    } else {
                        CometStorage.setObject(USER_OBJECT_KEY, {
                            signedin: false,
                            username: ''
                        });

                        this.props.localstorage();

                        this.setState({
                            error_message: d.result,
                            password: ''
                        });
                    }
                }
            });
        }

        e.preventDefault();
    }

    render() {
        if (this.props.user_object.signedin) {
            if (this.state.just_signed_in) {
                if ('deeplink' in this.props.match.params) {
                    let regex = new RegExp('^' + process.env.WEBSITE_DOMAIN)
                    let decodeduri = decodeURIComponent(this.props.match.params.deeplink)

                    if (regex.test(decodeduri)) {
                        window.location.replace(decodeduri)
                        return
                    }
                }
            }

            return <Redirect to={HOME_URL} />
        }

        let message_html = '';
        if ("message" in this.props.match.params) {
            if (this.props.match.params.message in MESSAGES) {
                message_html = <div className="msg">
                    <span id="error_message">{MESSAGES[this.props.match.params.message]}</span>
                </div>;
            }
        }

        return <div>
            { message_html }

            <div className="error_msg">
                <span id="error_message">{this.state.error_message}</span>
            </div>
            <form id="signin_form" onSubmit={this.signin}>
                <input id="username" value={this.state.username} onChange={this.onchange_username} />
                <input id="password" type="password" value={this.state.password} onChange={this.onchange_password} />
                <input type="submit" value="Signin" />
            </form>
        </div>
    }
}

export default Login
