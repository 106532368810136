import React, {Component} from 'react'

class CallToActionComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div>
            <h1>Log in to start playing</h1>
        </div>
    }
}

export default CallToActionComponent
