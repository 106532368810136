import React, {Component} from 'react'
import $ from 'jquery';
import { Redirect } from "react-router-dom";
import {LOGIN_URL} from "../../WebsiteConstants";


class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email_address: '',
            message: '',
            password1: '',
            password2: ''
        };

        this.onchange_email_address = this.onchange_email_address.bind(this);
        this.resetpassword = this.resetpassword.bind(this);
        this.onchange_password1 = this.onchange_password1.bind(this);
        this.onchange_password2 = this.onchange_password2.bind(this);
        this.resetpasswordconfirmed = this.resetpasswordconfirmed.bind(this);
    }

    onchange_email_address(e) {
        this.setState({
            email_address: e.target.value.trim()
        });
    }

    onchange_password1(e) {
        this.setState({
            password1: e.target.value.trim()
        });
    }

    onchange_password2(e) {
        this.setState({
            password2: e.target.value.trim()
        });
    }

    resetpassword(e) {
        e.preventDefault();

        if (!this.props.user_object.signedin) {
            $.ajax({
                url: process.env.API_URL + "resetpasswordrequest",
                xhrFields: {
                    withCredentials: true
                },
                type: "post",
                data: {
                    email_address: this.state.email_address,
                },
                success: d => {
                    this.setState({
                        email_address: '',
                        message: 'Password request sent'
                    });
                }
            });
        }
    }

    resetpasswordconfirmed(e) {
        e.preventDefault();

        if (this.state.password1 !== this.state.password2) {
            this.setState({
                message: 'Passwords must match'
            });

            return;
        }

        $.ajax({
            url: process.env.API_URL + "resetpasswordconfirm",
            xhrFields: {
                withCredentials: true
            },
            type: "post",
            data: {
                password: this.state.password1,
                token: this.props.match.params.token
            },
            success: d => {
                if (d.code !== 'ok') {
                    this.setState({
                        message: 'Expired reset token'
                    });
                } else {
                    window.location.replace(process.env.WEBSITE_DOMAIN + LOGIN_URL + "/1");
                }
            }
        })
    }

    render() {
        if (this.props.user_object.signedin) {
            return <Redirect to="/" />;
        }

        let form_html = '';
        if ("token" in this.props.match.params) {
            form_html = <form id="resetpassword_form" onSubmit={this.resetpasswordconfirmed}>
                <span>New password</span><br />
                <input id="pass1" type="password" value={this.state.password1} onChange={this.onchange_password1} /><br />
                <span>Repeat new password</span><br />
                <input id="pass2" type="password" value={this.state.password2} onChange={this.onchange_password2} /><br />
                <input type="submit" value="Change Password" />
            </form>
        } else {
            form_html = <form id="resetpassword_form" onSubmit={this.resetpassword}>
                <span>Email Address</span><br />
                <input id="email" type="text" value={this.state.email_address} onChange={this.onchange_email_address} /><br />
                <input type="submit" value="Send Reset Request" />
            </form>
        }

        return <div>
            <h3>{this.state.message}</h3>
            {form_html}
        </div>
    }
}

export default ResetPassword
