import React, {Component} from 'react'
import {HOME_URL, LOGIN_URL} from "../../WebsiteConstants";
import {Link} from "react-router-dom";

class LoginLinkComponent extends Component {
    constructor(props) {
        super(props);

        this.generate_deeplink = this.generate_deeplink.bind(this)
    }

    generate_deeplink() {
        let regex = new RegExp('deeplink')
        let deeplink = ''

        if (!regex.test(this.props.location.pathname)) {
            deeplink = '/deeplink/' + encodeURIComponent(process.env.WEBSITE_DOMAIN + this.props.location.pathname.substr(1))
        }

        return deeplink
    }

    render() {
        let loginlink = <Link to={HOME_URL + LOGIN_URL + this.generate_deeplink()}>Login</Link>

        return <div>
            {!this.props.user_object.signedin && loginlink}
        </div>
    }
}

export default LoginLinkComponent
