import React, {Component} from 'react'
import $ from 'jquery';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Index from "./pages/Index/Index";
import Signup from "./pages/Signup/Signup";
import PokerPatternStorage from "../../../web-lib/PokerPatternStorage";
import {USER_OBJECT_KEY} from "../../../web-lib/Constants";
import Profile from "./pages/Profile/Profile";
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Logout from "./components/Logout";
import {
  HOME_URL,
  LOGIN_URL,
  PROFILE_URL,
  RESETPASSWORD_URL,
  SIGNUP_URL
} from "./WebsiteConstants";
import NavigationBarComponent from "./components/NavigationBarComponent/NavigationBarComponent";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_object: {
        signedin: false,
        username: ''
      }
    };

    this.localStorageUpdated = this.localStorageUpdated.bind(this);
    this.checksession = this.checksession.bind(this);
  }

  componentDidMount() {
    window.addEventListener('storage', this.localStorageUpdated);

    this.checksession();

    let self = this;
    setInterval(function() {
      self.checksession();
    }, 5000);
  }

  checksession() {
    $.ajax({
      url: process.env.API_URL + "checksession",
      xhrFields: {
        withCredentials: true
      },
      type: "get",
      success: d => {
        if (d.code === 'ok') {
          if (d.result === false) {
            PokerPatternStorage.setObject(USER_OBJECT_KEY, {
              signedin: false,
              username: ''
            });
          } else {
            PokerPatternStorage.setObject(USER_OBJECT_KEY, {
              signedin: true,
              username: d.result.username
            });
          }

          this.localStorageUpdated();
        }
      }
    });
  }

  localStorageUpdated() {
    let user_object = PokerPatternStorage.getObject(USER_OBJECT_KEY);

    if (user_object === null) {
      this.setState({
        user_object: {
          signedin: false,
          username: ''
        }
      });
    } else {
      this.setState({
        user_object: user_object
      });
    }
  }

  render() {
    let logout_link;

    if (this.state.user_object.signedin) {
      logout_link = <Logout localstorage={this.localStorageUpdated} user_object={this.state.user_object} />;
    }

    return <Router>
      <div>
        <NavigationBarComponent user_object={this.state.user_object} />

        <span>{this.state.user_object.signedin === true ? 'Signed in as ' + this.state.user_object.username : 'Not signed in'}</span>
        {logout_link}


        {/* Root routes (also the discussion listing */}
        <Route path={HOME_URL} exact render={
          (props) => <Index {...props} user_object={this.state.user_object} />
        } />


        {/* Signup routes */}
        <Route path={HOME_URL + SIGNUP_URL} exact render={
          (props) => <Signup {...props} localstorage={this.localStorageUpdated} user_object={this.state.user_object} />
        } />


        {/* Login routes */}
        <Route path={HOME_URL + LOGIN_URL} exact render={
          (props) => <Login {...props} localstorage={this.localStorageUpdated} user_object={this.state.user_object} />
        } />
        <Route path={HOME_URL + LOGIN_URL + "/deeplink/:deeplink"} exact render={
          (props) => <Login {...props} localstorage={this.localStorageUpdated} user_object={this.state.user_object} />
        } />
        <Route path={HOME_URL + LOGIN_URL + "/:message"} exact render={
          (props) => <Login {...props} localstorage={this.localStorageUpdated} user_object={this.state.user_object} />
        } />


        {/* Profile routes */}
        <Route path={HOME_URL + PROFILE_URL + "/:username"} exact render={
          (props) => <Profile {...props} user_object={this.state.user_object} />
        } />


        {/* Reset password routes */}
        <Route path={HOME_URL + RESETPASSWORD_URL} exact render={
          (props) => <ResetPassword {...props} user_object={this.state.user_object} />
        } />
        <Route path={HOME_URL + RESETPASSWORD_URL + "/:token"} exact render={
          (props) => <ResetPassword {...props} user_object={this.state.user_object} />
        } />
      </div>
    </Router>
  }
}

export default App
