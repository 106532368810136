import React, {Component} from 'react'
import {
    HOME_URL,
    PROFILE_URL,
    RESETPASSWORD_URL,
    SIGNUP_URL
} from "../../WebsiteConstants";
import {Link, Route} from "react-router-dom";
import LoginLinkComponent from "../LoginLinkComponent/LoginLinkComponent";

class NavigationBarComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let profile_link;
        let signup_link;
        let reset_password_link;

        if (this.props.user_object.signedin) {
            profile_link = <li>
                <Link to={HOME_URL + PROFILE_URL + "/" + this.props.user_object.username}>Profile</Link>
            </li>;
        }

        if (!this.props.user_object.signedin) {
            signup_link = <li>
                <Link to={HOME_URL + SIGNUP_URL}>Signup</Link>
            </li>;

            reset_password_link = <li>
                <Link to={HOME_URL + RESETPASSWORD_URL}>Reset Password</Link>
            </li>;
        }


        return <div>
            <nav>
                <ul>
                    <li>
                        <Link to={HOME_URL}>Home</Link>
                    </li>
                    {signup_link}

                    <Route path={HOME_URL} render={
                        (props) => <LoginLinkComponent {...props} user_object={this.props.user_object} />
                    } />

                    {profile_link}
                    {reset_password_link}
                </ul>
            </nav>
        </div>
    }
}

export default NavigationBarComponent
