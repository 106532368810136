import React, {Component} from 'react'
import LoadingIndicator from '../../../components/LoadingIndicator'
import $ from 'jquery';

class ChangePasswordComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error_message: '',
            old_password: '',
            new_password: '',
            new_repeat_password: '',
            submitted: false
        };

        this.changepassword = this.changepassword.bind(this);
        this.onchange_old_password = this.onchange_old_password.bind(this);
        this.onchange_new_password = this.onchange_new_password.bind(this);
        this.onchange_repeat_new_password = this.onchange_repeat_new_password.bind(this);
    }

    onchange_old_password(e) {
        this.setState({
            old_password: e.target.value.trim()
        });
    }

    onchange_new_password(e) {
        this.setState({
            new_password: e.target.value.trim()
        });
    }

    onchange_repeat_new_password(e) {
        this.setState({
            new_repeat_password: e.target.value.trim()
        });
    }

    changepassword(e) {
        e.preventDefault();

        if (this.props.user_object.signedin === true) {
            if (this.state.old_password === '' || this.state.new_password === '' || this.state.new_repeat_password === '') {
                this.setState({error_message: 'Entries can not be blank'});
            } else if (this.state.new_password !== this.state.new_repeat_password) {
                this.setState({ error_message: 'Passwords did not match' });
            } else {
                this.setState({
                    error_message: '',
                    submitted: true
                });

                $.ajax({
                    url: process.env.API_URL + "changepassword",
                    xhrFields: {
                        withCredentials: true
                    },
                    type: "post",
                    data: {
                        old_password: this.state.old_password,
                        new_password: this.state.new_password
                    },
                    success: d => {
                        this.setState({
                            error_message: d.result,
                            old_password: '',
                            new_password: '',
                            new_repeat_password: '',
                            submitted: false
                        });
                    }
                });
            }
        }
    }

    render() {
        let li = '';
        if (this.state.submitted) {
            li = <LoadingIndicator />
        }

        return <div>
            <div className="error_msg">
                <span id="error_message">{this.state.error_message}</span>
            </div>
            <form id="changepassword_form" onSubmit={this.changepassword}>
                <span>Old Password</span><br />
                <input id="old_password" type="password" value={this.state.old_password} onChange={this.onchange_old_password} /><br />
                <span>New Password</span><br />
                <input id="new_password" type="password" value={this.state.new_password} onChange={this.onchange_new_password} /><br />
                <span>Repeat New Password</span><br />
                <input id="new_repeat_password" type="password" value={this.state.new_repeat_password} onChange={this.onchange_repeat_new_password} /><br />
                <input type="submit" value="Change Password" disabled={this.state.submitted} />{ li }
            </form>
        </div>
    }
}

export default ChangePasswordComponent
