import React, {Component} from 'react'
import CallToActionComponent from "./components/CallToActionComponent";
import HandReplayer from "./components/HandReplayer";
import $ from "jquery";


class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            current_view_tags: [],
            message: ''
        };

        this.set_current_view_tags = this.set_current_view_tags.bind(this)
    }

    componentDidMount() {
        if ('tag' in this.props.match.params) {
            $.ajax({
                url: process.env.API_URL + "tag/" + this.props.match.params.tag,
                xhrFields: {
                    withCredentials: true
                },
                type: "get",
                success: d => {
                    if (d.code === 'ok') {
                        if (d.result.length === 0) {
                            this.setState({
                                current_view_tags: [{}],
                                message: 'No discussions found for tag "' + this.props.match.params.tag + '", showing all discussions'
                            })
                        } else {
                            this.setState({
                                current_view_tags: d.result,
                                message: ''
                            })
                        }
                    }
                }
            });
        }
    }

    set_current_view_tags(tags) {
        this.setState({
            current_view_tags: tags
        });
    }

    render() {
        return <div>
            {!this.props.user_object.signedin && <CallToActionComponent />}

            {this.props.user_object.signedin && <HandReplayer />}
        </div>
    }
}

export default Index
