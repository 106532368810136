import React, {Component} from 'react'
import $ from 'jquery';
import CometStorage from "../../../../web-lib/PokerPatternStorage";
import {USER_OBJECT_KEY} from "../../../../web-lib/Constants";

class Logout extends Component {
    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
    }

    logout(e) {
        e.preventDefault();

        $.ajax({
            url: process.env.API_URL + "logout",
            xhrFields: {
                withCredentials: true
            },
            type: "post",
            success: () => {
                CometStorage.setObject(USER_OBJECT_KEY, {
                    signedin: false,
                    username: ''
                });
                this.props.localstorage();
            }
        });
    }

    render() {
        return <div><a onClick={this.logout} href="#">Logout</a></div>
    }
}

export default Logout
