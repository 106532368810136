import React, {Component} from 'react'
import ChangePasswordComponent from "./components/ChangePasswordComponent";
import StatisticsGraphComponent from './components/StatisticsGraphComponent';
import $ from "jquery";


class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email_address: '',
            is_it_us: false
        }

        this.get_user_meta = this.get_user_meta.bind(this)
    }

    componentDidMount() {
        if ('username' in this.props.match.params) {
            this.setState({
                is_it_us: (this.props.user_object.signedin && this.props.user_object.username === this.props.match.params.username)
            })

            this.get_user_meta();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.username !== this.props.match.params.username) {
            this.setState({
                is_it_us: (this.props.user_object.signedin && this.props.user_object.username === this.props.match.params.username)
            });

            this.get_user_meta();
        }

        if (prevProps.user_object.signedin !== this.props.user_object.signedin) {
            this.setState({
                is_it_us: (this.props.user_object.signedin && this.props.user_object.username === this.props.match.params.username)
            });

            this.get_user_meta();
        }
    }

    get_user_meta() {
        $.ajax({
            url: process.env.API_URL + "profile/meta/" + this.props.match.params.username,
            xhrFields: {
                withCredentials: true
            },
            type: "get",
            success: d => {
                if (d.code === 'ok') {
                    this.setState({
                        email_address: d.result.email_address
                    })
                }
            }
        });
    }

    render() {
        return <div>
            { this.state.is_it_us && <ChangePasswordComponent user_object={this.props.user_object} /> }

            <h2>{this.props.match.params.username}</h2>
            { this.state.is_it_us && <div>{this.state.email_address}</div> }
            { this.state.is_it_us && <StatisticsGraphComponent user_object={this.props.user_object} /> }
        </div>
    }
}

export default Profile
