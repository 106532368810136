import React, {Component} from 'react'
import $ from "jquery";
import LoadingIndicator from "../../../components/LoadingIndicator";
import {Sprite} from "react-spritesheet";

class HandReplayer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            playback: {
                oid: '', //todo remove me
                board_flop: [],
                board_turn: '',
                board_river: '',
                stakes: '',
                players: {},
                preflop: {},
                flop: {},
                turn: {},
                river: {},
                possible_answers: []
            },
            which_streets: {
                preflop_hands: true,
                flop_hands: true,
                turn_hands: true,
                river_hands: true
            },
            dirty: true,
            submitted_play: false,
            submitted_answer: false,
            players: ['Select a player'],
            player: 'Select a player',
            show_next_hand_button: false,
            answer: ''
        };

        this.start_playing = this.start_playing.bind(this)
        this.choose_player = this.choose_player.bind(this)
        this.submit_answer = this.submit_answer.bind(this)
        this.next_hand = this.next_hand.bind(this)
        this.determine_card = this.determine_card.bind(this)
    }

    componentDidMount() {
        $.ajax({
            url: process.env.API_URL + "player_list",
            xhrFields: {
                withCredentials: true
            },
            type: "get",
            success: d => {
                if (d.code === 'ok') {
                    d.result.sort()
                    d.result.push('Select a player')
                    d.result.reverse()

                    this.setState({
                        players: d.result
                    });
                }
            }
        });
    }

    onchange_hands(action, type) {
        let that = this
        return function(e) {
            let which_streets = that.state.which_streets

            switch (type) {
                case 'checkbox':
                    which_streets[action] = e.target.checked
                    break
                case 'input':
                    which_streets[action] = e.target.value
                    break
            }

            that.setState({
                which_streets: which_streets,
                dirty: true
            });
        }
    }

    start_playing() {
        // e.preventDefault();

        this.setState({
            submitted_play: true,
            answer: ''
        })

        $.ajax({
            url: process.env.API_URL + "retrieve_playback",
            xhrFields: {
                withCredentials: true
            },
            type: "post",
            data: {
                player: this.state.player,
                which_streets: this.state.which_streets
            },
            success: d => {
                this.setState({
                    playback: {
                        oid: d.result.playback.oid, //todo remove me
                        board_flop: ('board_flop' in d.result.playback) ? d.result.playback.board_flop : [],
                        board_turn: ('board_turn' in d.result.playback) ? d.result.playback.board_turn : '',
                        board_river: ('board_river' in d.result.playback) ? d.result.playback.board_river : '',
                        stakes: ('stakes' in d.result.playback) ? d.result.playback.stakes : '',
                        players: ('players' in d.result.playback) ? d.result.playback.players : {},
                        preflop: ('preflop' in d.result.playback) ? d.result.playback.preflop : {},
                        flop: ('flop' in d.result.playback) ? d.result.playback.flop : {},
                        turn: ('turn' in d.result.playback) ? d.result.playback.turn : {},
                        river: ('river' in d.result.playback) ? d.result.playback.river : {},
                        possible_answers: d.result.playback.possible_answers
                    },
                    submitted_play: false,
                    submitted_answer: false,
                    dirty: false
                });
            }
        });
    }

    revert_playback() {
        this.setState({
            playback: {
                oid: '', //todo remove me
                board_flop: [],
                board_turn: '',
                board_river: '',
                stakes: '',
                players: {},
                preflop: {},
                flop: {},
                turn: {},
                river: {},
                possible_answers: []
            }
        });
    }

    submit_answer(pa) {
        this.setState({
            submitted_answer: true
        })

        $.ajax({
            url: process.env.API_URL + "submit_answer",
            xhrFields: {
                withCredentials: true
            },
            type: "post",
            data: {
                answer: pa,
            },
            success: d => {
                this.setState({
                    show_next_hand_button: true,
                    answer: d.result.answer
                });
            }
        });
    }

    choose_player(e) {
        this.setState({
            player: e.target.value
        })
    }

    next_hand() {
        this.setState({
            show_next_hand_button: false,
            answer: ''
        })

        this.revert_playback()
        this.start_playing()
    }

    determine_card(hole_card) {
        if (hole_card === '?') { return [777, 0] }

        if (hole_card === 'Ad') { return [0, 0] }
        if (hole_card === 'Ah') { return [0, 72] }
        if (hole_card === 'As') { return [0, 145] }
        if (hole_card === 'Ac') { return [0, 218] }

        if (hole_card === '2d') { return [52, 0] }
        if (hole_card === '2h') { return [52, 72] }
        if (hole_card === '2s') { return [52, 145] }
        if (hole_card === '2c') { return [52, 216] }

        if (hole_card === '3d') { return [104, 0] }
        if (hole_card === '3h') { return [104, 72] }
        if (hole_card === '3s') { return [104, 145] }
        if (hole_card === '3c') { return [104, 216] }

        if (hole_card === '4d') { return [156, 0] }
        if (hole_card === '4h') { return [156, 72] }
        if (hole_card === '4s') { return [156, 145] }
        if (hole_card === '4c') { return [156, 218] }

        if (hole_card === '5d') { return [208, 0] }
        if (hole_card === '5h') { return [208, 72] }
        if (hole_card === '5s') { return [208, 145] }
        if (hole_card === '5c') { return [208, 216] }

        if (hole_card === '6d') { return [259, 0] }
        if (hole_card === '6h') { return [260, 72] }
        if (hole_card === '6s') { return [260, 145] }
        if (hole_card === '6c') { return [260, 216] }

        if (hole_card === '7d') { return [311, 0] }
        if (hole_card === '7h') { return [311, 73] }
        if (hole_card === '7s') { return [311, 145] }
        if (hole_card === '7c') { return [311, 218] }

        if (hole_card === '8d') { return [364, 0] }
        if (hole_card === '8h') { return [364, 72] }
        if (hole_card === '8s') { return [364, 145] }
        if (hole_card === '8c') { return [362, 218] }

        if (hole_card === '9d') { return [415, 0] }
        if (hole_card === '9h') { return [415, 72] }
        if (hole_card === '9s') { return [415, 145] }
        if (hole_card === '9c') { return [415, 218] }

        if (hole_card === 'Td') { return [466, 0] }
        if (hole_card === 'Th') { return [466, 72] }
        if (hole_card === 'Ts') { return [466, 145] }
        if (hole_card === 'Tc') { return [466, 218] }

        if (hole_card === 'Jd') { return [518, 0] }
        if (hole_card === 'Jh') { return [518, 72] }
        if (hole_card === 'Js') { return [518, 145] }
        if (hole_card === 'Jc') { return [518, 218] }

        if (hole_card === 'Qd') { return [570, 0] }
        if (hole_card === 'Qh') { return [570, 72] }
        if (hole_card === 'Qs') { return [570, 145] }
        if (hole_card === 'Qc') { return [570, 218] }

        if (hole_card === 'Kd') { return [622, 0] }
        if (hole_card === 'Kh') { return [622, 72] }
        if (hole_card === 'Ks') { return [622, 145] }
        if (hole_card === 'Kc') { return [622, 218] }

        //unknown, show the question mark card
        return [674, 0]
    }

    render() {
        let li = '';
        if (this.state.submitted_play) {
            li = <LoadingIndicator />
        }
        let li2 = '';
        if (this.state.submitted_answer) {
            li2 = <LoadingIndicator />
        }

        return <div>
            <p>Choose your options then click "Start Hand" to play</p>
            <div>
                <input id="preflop_hands" type="checkbox"
                       checked={this.state.which_streets.preflop_hands}
                       onChange={this.onchange_hands('preflop_hands', 'checkbox')}/>
                <label htmlFor="preflop_hands">Preflop Hands</label>
                <input id="flop_hands" type="checkbox"
                       checked={this.state.which_streets.flop_hands}
                       onChange={this.onchange_hands('flop_hands', 'checkbox')}/>
                <label htmlFor="flop_hands">Flop Hands</label>
                <input id="turn_hands" type="checkbox"
                       checked={this.state.which_streets.turn_hands}
                       onChange={this.onchange_hands('turn_hands', 'checkbox')}/>
                <label htmlFor="turn_hands">Turn Hands</label>
                <input id="river_hands" type="checkbox"
                       checked={this.state.which_streets.river_hands}
                       onChange={this.onchange_hands('river_hands', 'checkbox')}/>
                <label htmlFor="river_hands">River Hands</label>
            </div>

            <div style={{marginTop: '20px'}}>
                <label htmlFor="player_picker">Players</label>
                <select id="player_picker" value={this.state.player} onChange={this.choose_player}>
                    {this.state.players.map(player => (
                        <option value={player} key={player}>{player}</option>
                    ))}
                </select>

                {/*<form id="start_form" onSubmit={this.start_playing}>*/}
                    <input type="submit" onClick={() => this.start_playing()} value="Start Playing" disabled={this.state.player === 'Select a player' || !this.state.dirty || this.state.submitted_play} />{ li }
                {/*</form>*/}
            </div>

            <div className="playback">
                <p>{this.state.playback.oid}</p>
                <p className="playback_stakes">{this.state.playback.stakes}</p>
                <div className="playback_players">
                    {Object.keys(this.state.playback.players).map(k => (
                        <div key={k}>
                            <p>{this.state.playback.players[k].name} ({this.state.playback.players[k].chip_count} chips)
                                {this.state.playback.players[k].is_sb && ', small blind'}
                                {this.state.playback.players[k].is_bb && ', big blind'}
                                {this.state.playback.players[k].is_button && ', button'}
                            </p>

                            <div style={{display:'inline-block'}}>
                                <Sprite filename="img/poker_cards_sprite.png" x={ this.determine_card(this.state.playback.players[k].hole_card_1)[0] } y={ this.determine_card(this.state.playback.players[k].hole_card_1)[1] } width={43} height={63} />
                            </div>
                            <div style={{display:'inline-block', marginLeft:'5px'}}>
                                <Sprite filename="img/poker_cards_sprite.png" x={ this.determine_card(this.state.playback.players[k].hole_card_2)[0] } y={ this.determine_card(this.state.playback.players[k].hole_card_2)[1] } width={43} height={63} />
                            </div>
                        </div>

                    ))}
                </div>
                <div className="playback_preflop" style={{display:'inline-block'}}>
                    <p>{Object.keys(this.state.playback.preflop).length > 0 ? 'PREFLOP' : ''}</p>
                    {Object.keys(this.state.playback.preflop).map(k => (
                        <div key={k}>
                            <p>{this.state.playback.preflop[k].player.name} ({this.state.playback.preflop[k].player.chip_count}) - {this.state.playback.preflop[k].action} ({this.state.playback.preflop[k].amount})</p>
                        </div>

                    ))}
                </div>
                <div className="playback_flop" style={{display:'inline-block', marginLeft:'50px'}}>
                    { Object.keys(this.state.playback.flop).length > 0 ? 'FLOP: (' : '' }
                    {Object.keys(this.state.playback.flop).length > 0 ?
                        this.state.playback.board_flop.map((v, i) => (
                            <div key={i} style={{display:'inline-block', marginLeft:'5px'}}>
                                <Sprite filename="img/poker_cards_sprite.png" x={ this.determine_card(v)[0] } y={ this.determine_card(v)[1] } width={43} height={63} />
                            </div>
                        )) : ''}
                    { Object.keys(this.state.playback.flop).length > 0 ? ')' : '' }

                    {Object.keys(this.state.playback.flop).map(k => (
                        <div key={k}>
                            <p>{this.state.playback.flop[k].player.name} ({this.state.playback.flop[k].player.chip_count}) - {this.state.playback.flop[k].action} ({this.state.playback.flop[k].amount})</p>
                        </div>

                    ))}
                </div>
                <div className="playback_turn" style={{display:'inline-block', marginLeft:'50px'}}>
                    { Object.keys(this.state.playback.turn).length > 0 ? 'TURN: (' : '' }
                    {Object.keys(this.state.playback.turn).length > 0 ?
                        <div style={{display:'inline-block', marginLeft:'5px'}}>
                            <Sprite filename="img/poker_cards_sprite.png" x={ this.determine_card(this.state.playback.board_turn)[0] } y={ this.determine_card(this.state.playback.board_turn)[1] } width={43} height={63} />
                        </div> : ''}
                    { Object.keys(this.state.playback.turn).length > 0 ? ')' : '' }

                    {Object.keys(this.state.playback.turn).map(k => (
                        <div key={k}>
                            <p>{this.state.playback.turn[k].player.name} ({this.state.playback.turn[k].player.chip_count}) - {this.state.playback.turn[k].action} ({this.state.playback.turn[k].amount})</p>
                        </div>

                    ))}
                </div>
                <div className="playback_river" style={{display:'inline-block', marginLeft:'50px'}}>
                    { Object.keys(this.state.playback.river).length > 0 ? 'RIVER: (' : '' }
                    {Object.keys(this.state.playback.river).length > 0 ?
                    <div style={{display:'inline-block', marginLeft:'5px'}}>
                        <Sprite filename="img/poker_cards_sprite.png" x={ this.determine_card(this.state.playback.board_river)[0] } y={ this.determine_card(this.state.playback.board_river)[1] } width={43} height={63} />
                    </div> : ''}
                    { Object.keys(this.state.playback.river).length > 0 ? ')' : '' }

                    {Object.keys(this.state.playback.river).map(k => (
                        <div key={k}>
                            <p>{this.state.playback.river[k].player.name} ({this.state.playback.river[k].player.chip_count}) - {this.state.playback.river[k].action} ({this.state.playback.river[k].amount})</p>
                        </div>

                    ))}
                </div>
                <div style={{display:'inline-block', marginLeft:'50px'}}>
                    {!this.state.show_next_hand_button && <div className="possible_answers">
                        {this.state.playback.possible_answers.map(pa => (
                            <button key={pa} onClick={() => this.submit_answer(pa)} disabled={this.state.submitted_answer === true}>{pa.toUpperCase()}</button>
                        ))}{ li2 }
                    </div>}
                    {this.state.answer !== '' && <p>{this.state.answer}</p>}
                    {this.state.show_next_hand_button && <div className="show_next_hand">
                        <button onClick={() => this.next_hand()}>Play Next Hand</button>
                    </div>}
                </div>
            </div>
        </div>
    }
}

export default HandReplayer
